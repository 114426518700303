import React, { Fragment } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { createMuiTheme, ThemeProvider, CssBaseline } from '@material-ui/core';

import './App.css';

import Landing from './Components/Landing/Landing';
import ComingSoon from './Components/Landing/ComingSoon';
// import Login from './Components/Login/Login'
// import Pricing from './Components/Pricing/Pricing';
// import Contact from './Components/Contact/Contact';
// import Onboarding from './Components/Onboarding/Onboarding'
// import Dashboard from "./Components/Dashboard/Dashboard";
// import ConfirmEmail from './Components/Register/ConfirmEmail'

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#11D87A",
      main: "#0da95f",
    },
    secondary: {
      main: '#ffffff'
    }
  },
});


export default function App() {
  return (
    <Fragment>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Landing />} />
            <Route exact path="/coming-soon" element={<ComingSoon />} />
            {/* <Route exact path="/pricing" element={<Pricing />} />
            <Route path="/login" element={<Login />} />
            <Route path="/contact" element={<Contact />} /> 
            <Route path="/logout" element={<Logout/>} />
            <Route path="/register" element={<Onboarding />} />
            <Route path="/dashboard" element={<Dashboard/>} />
            <Route path="/confirm-email" element={<ConfirmEmail />} /> */}
          </Routes>
        </BrowserRouter >
      </ThemeProvider>
    </Fragment>
  )
}

