import React from 'react'
import { AppBar, Toolbar, Typography, Button, makeStyles } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
    textDecoration: 'none',
    fontSize: '2rem;',
    marginLeft: '2rem'
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
}))

export default function NavBar() {
  const classes = useStyles()
  return (
    <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Typography component={RouterLink} to="/" variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
          SpreadShop
        </Typography>
        <nav>
          {/* <Link component={RouterLink} variant="button" color="textPrimary" to="/pricing" className={classes.link}>
            Pricing
          </Link>
          <Link component={RouterLink} variant="button" color="textPrimary" to="/contact" className={classes.link}>
            Contact
          </Link> */}
        </nav>
        {/* <Button component={RouterLink} to="/login" variant="outlined" className={classes.link}>
          Login
        </Button> */}
        <Button component={RouterLink} to="/coming-soon" color="primary" variant="contained" className={classes.link}>
          Get Started
        </Button>
      </Toolbar>
    </AppBar>
  )
} 
