import React from 'react'
import { Button, Card, Container, Link, Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  parent: {
    display: "flex",
    justifyContent: "center"
  },
  signupBox: {
    backgroundColor: theme.palette.primary.main,
    opacity: "0.8",
    color: "white",
    width: "50%",
    textAlign: "center",
    padding: "2rem"
  },
  inputBox: {
    margin: "1rem",
    '& fieldset': {
      color: "#ffffff",
      borderColor: "rgba(255, 255, 255, 0.8)",
    },
  },
  signupButton: {
    backgroundColor: "white"
  }
})
)


export default function NewsletterSignUpBox() {
  const classes = useStyles()
  // const [emailAddress, setEmailAddress] = useState()

  // const submitNewsletter = () => {
  //   console.log("submitNewsletter, NOT YET IMPLEMENTED")
  //   console.log(emailAddress, "is now subscribed to the newsletter.")
  // }

  return (
    <Container className={classes.parent}>
      <Card className={classes.signupBox}>
        <Typography variant="h4" color="inherit" style={{ fontWeight: 'bold' }} gutterBottom>
          Join Our Newsletter
        </Typography>
        <Typography variant="h6" color="inherit" gutterBottom>
          Subscribe to out newsletter to get product updates <br /> and ecommerce tips straight in your mailbox!
        </Typography>
        {/* <div className={classes.inputBox}>
          <TextField variant="outlined" color='secondary' label="Email Address"
            InputLabelProps={{
              style: {
                color: "rgba(255, 255, 255, 0.8)"
              }
            }}
            onChange={
              (evt) => (setEmailAddress(evt.target.value))
            } />
        </div> */}
        {/* <Button  variant="contained" onClick={submitNewsletter}>
          Subscribe
        </Button> */}
        <Button className={classes.signupButton} target="_blank" size="large" component={Link} href="https://cdn.forms-content.sg-form.com/dc8689e3-e4ac-11ea-a0af-1a07bfcd2b9c" variant="contained">
          Get the newsletter
        </Button>
      </Card>
    </Container >
  )
}
