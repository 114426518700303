import React from 'react'

import { Button, Container, Typography, makeStyles } from '@material-ui/core'

import NavBar from '../NavBar/NavBar'
import NewsLetterSignUpBox from './NewsletterSignUpBox'
import Footer from '../Footer/Footer'
import { Link } from 'react-router-dom';

import undrawWebShop from '../../Assets/undraw_web_shop.svg'


const useStyles = makeStyles((theme) => ({
  heroContent: {
    padding: theme.spacing(8, 0, 6),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  heroSide: {
    width: "45%"
  }
})
)

export default function Landing() {
  const classes = useStyles()

  return (
    <div>
      <NavBar />
      <Container maxWidth="md" component="main" className={classes.heroContent}>
        <div className={classes.heroSide}>
          <Typography variant="h4" color="inherit" style={{ fontWeight: 'bold' }}>
            TURN A SPREADSHEET INTO YOUR WEBSTORE
          </Typography>
          <Typography variant="h6" color="inherit" gutterBottom>
            Get your webstore up and running in minutes without learning to code.
          </Typography>
          <Link to="/coming-soon">
            <Button color="primary" variant="contained">
              Get Started
          </Button>
          </Link>
        </div>
        <div className={classes.heroSide}>
          <img style={{ width: "100%" }} src={undrawWebShop} alt="" />
        </div>
      </Container>
      <NewsLetterSignUpBox />
      <Footer />
    </div >
  )
}
