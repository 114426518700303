import React from 'react'
import NavBar from '../NavBar/NavBar'
import NewsLetterSignUpBox from './NewsletterSignUpBox'
import Footer from '../Footer/Footer'
import { Button, Container, Typography } from '@material-ui/core'


export default function ComingSoon() {
  return (
    <div>
      <NavBar />
      <Container maxWidth="md" component="main" style={{ padding: "2rem 0", display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Typography variant="h3" align="center" gutterBottom>Whoops you're early!</Typography>

        <Typography variant="h5" align="center" gutterBottom>
          Thank you for showing interest! But unfortunately, our product is not ready yet.
          You can sign up below to get updates about our development or fill in the questionnaire below to let us know how we can best help you.
        </Typography>

        <Button
          variant="contained"
          color="primary"
          size="large"
          href="https://forms.gle/e9x1DwZGzk5v3QKXA"
        >
          Leave your thoughts!
        </Button>
      </Container>
      <NewsLetterSignUpBox />
      <Footer />
    </div>
  )
}
